body {
  font-family: Inter;
  font-style: normal;
}

.Home {
  font-family: sans-serif;
  text-align: center;
}
.Home h1 {
  color: #800;
}
.Home h2 {
  color: #080;
}
.Home .Msg {
  margin: auto;
  max-width: 200px;
  color: #888;
  /*border: 1px #888 solid;*/
  padding: 10px;
}
.Home .Msg .SubMsg {
  padding: 10px;
}

.MuiModal-backdrop.MuiBackdrop-root {
  background-color: rgba(0, 0, 0, 0.1);
}

.d-none {
  position: absolute;
}
